import React from 'react'
import styled from 'styled-components'
import { HeroImageTitle } from 'components/new/hero'
import { Helmet } from 'react-helmet'
import ProductCategoryGrid from 'components/new/product-category-grid'
import Content from 'components/content-full-width'
import Layout from 'components/layout'
import { graphql } from 'gatsby'
import BreadcrumbsScript from 'components/scripts/breadcrumbs'

const items = [
  {
    link: '/construction/attachments/ag-attachments/',
    imageId: 'agAttachmentsImage',
    title: 'Ag Attachments',
    imageAlt: 'John Deere track loader with bale hugger attachment',
  },
  {
    link: '/construction/attachments/augers-trenchers/',
    imageId: 'augersTrenchersImage',
    title: 'Augers & Trenchers',
    imageAlt: 'John Deere skid steer with trencher attachment',
  },
  {
    link: '/construction/attachments/backhoes/',
    imageId: 'backhoesImage',
    title: 'Backhoes',
    imageAlt: 'John Deere track loader with backhoe attachment',
  },
  {
    link: '/construction/attachments/blades-scrapers/',
    imageId: 'bladesScrapersImage',
    title: 'Blades & Scrapers',
    imageAlt: 'John Deere skid steer with snow blade attachment',
  },
  {
    link: '/construction/attachments/brooms/',
    imageId: 'broomsImage',
    title: 'Brooms',
    imageAlt: 'John Deere skid steer with broom attachment',
  },
  {
    link: '/construction/attachments/buckets/',
    imageId: 'bucketsImage',
    title: 'Buckets',
    imageAlt: 'John Deere track loader with bucket attachment',
  },
  {
    link: '/construction/attachments/cutters-shredders-mulchers/',
    imageId: 'cuttersShreddersMulchersImage',
    title: 'Cutters, Shredders & Mulchers',
    imageAlt: 'John Deere track loader with mulching attachment',
  },
  {
    link: '/construction/attachments/forks-spears/',
    imageId: 'forksSpearsImage',
    title: 'Forks & Spears',
    imageAlt: 'John Deere track loader with pallet forks attachment',
  },
  {
    link: '/construction/attachments/grapples/',
    imageId: 'grapplesImage',
    title: 'Grapples',
    imageAlt: 'John Deere skid steer with grapple attachment',
  },
  {
    link: '/construction/attachments/hammers/',
    imageId: 'hammersImage',
    title: 'Hammers',
    imageAlt: 'John Deere skid steer with hammer attachment',
  },
  {
    link: '/construction/attachments/planers/',
    imageId: 'coldPlanersImage',
    title: 'Planers',
    imageAlt: 'John Deere skid steer with planter attachment',
  },
  {
    link: '/construction/attachments/plate-compactors/',
    imageId: 'plateCompactorsImage',
    title: 'Plate Compactors',
    imageAlt: 'John Deere compact excavator with plate compactor attachment',
  },
  {
    link: '/construction/attachments/rakes-tillers/',
    imageId: 'rakesTillersPlanersImage',
    title: 'Rakes & Tillers',
    imageAlt: 'John Deere track loader with root rake attachment',
  },
  {
    link: '/construction/attachments/rollers/',
    imageId: 'rollersImage',
    title: 'Rollers',
    imageAlt: 'John Deere track loader with roller attachment',
  },
  {
    link: '/construction/attachments/snow-attachments/',
    imageId: 'snowAttachmentsImage',
    title: 'Snow Attachments',
    imageAlt: 'John Deere skid steer with snow blower attachment',
  },
  {
    link: '/construction/attachments/steel-tracks/',
    imageId: 'steelTracksImage',
    title: 'Steel Tracks',
    imageAlt: 'Steel tracks for skid steers',
  },
]

const breadcrumbs = [
  {
    name: 'Home',
    schemaName: 'Hutson Inc',
    link: '/',
  },
  {
    name: 'Construction Equipment',
    link: '/construction/',
  },
  {
    name: 'Attachments',
    link: '/construction/attachments/',
  },
]

const ConstructionAttachmentsPage = ({ data }) => {
  const { heroImage } = data
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Construction Attachments | Hutson Inc</title>
      </Helmet>

      <BreadcrumbsScript breadcrumbs={breadcrumbs} />

      <Hero
        image={heroImage.childImageSharp.gatsbyImageData}
        title='Construction Attachments'
        breadcrumbs={breadcrumbs}
      />

      <Content>
        <ProductCategoryGrid categories={gridItems} />
      </Content>
    </Layout>
  )
}

const Hero = styled(HeroImageTitle)`
  height: 300px;

  @media ${props => props.theme.mediaQuery.medium} {
    height: 400px;
  }

  @media ${props => props.theme.mediaQuery.largeUp} {
    height: 450px;
  }
`

export const pageQuery = graphql`
  query constructionAttachmentsQuery {
    heroImage: file(relativePath: { eq: "construction/attachments/bale-hugger.jpg" }) {
      ...FullWidthImage
    }
    agAttachmentsImage: file(
      relativePath: { eq: "construction/attachments/cce-ag-attachments.jpg" }
    ) {
      ...SharpImage480
    }
    augersTrenchersImage: file(
      relativePath: { eq: "construction/attachments/cce-augers-trenchers.jpg" }
    ) {
      ...SharpImage480
    }
    backhoesImage: file(relativePath: { eq: "construction/attachments/cce-backhoes.jpg" }) {
      ...SharpImage480
    }
    bladesScrapersImage: file(
      relativePath: { eq: "construction/attachments/cce-blades-scrapers.jpg" }
    ) {
      ...SharpImage480
    }
    broomsImage: file(relativePath: { eq: "construction/attachments/cce-brooms.jpg" }) {
      ...SharpImage480
    }
    bucketsImage: file(relativePath: { eq: "construction/attachments/cce-buckets.jpg" }) {
      ...SharpImage480
    }
    coldPlanersImage: file(relativePath: { eq: "construction/attachments/cce-cold-planers.jpg" }) {
      ...SharpImage480
    }
    cuttersShreddersMulchersImage: file(
      relativePath: { eq: "construction/attachments/cce-cutters-shredders-mulchers.jpg" }
    ) {
      ...SharpImage480
    }
    forksSpearsImage: file(relativePath: { eq: "construction/attachments/cce-forks-spears.jpg" }) {
      ...SharpImage480
    }
    grapplesImage: file(relativePath: { eq: "construction/attachments/cce-grapples.jpg" }) {
      ...SharpImage480
    }
    hammersImage: file(relativePath: { eq: "construction/attachments/cce-hammers.jpg" }) {
      ...SharpImage480
    }
    plateCompactorsImage: file(
      relativePath: { eq: "construction/attachments/cce-plate-compactors.jpg" }
    ) {
      ...SharpImage480
    }
    rakesTillersPlanersImage: file(
      relativePath: { eq: "construction/attachments/cce-rakes-tillers-planers.jpg" }
    ) {
      ...SharpImage480
    }
    rollersImage: file(relativePath: { eq: "construction/attachments/cce-rollers.jpg" }) {
      ...SharpImage480
    }
    snowAttachmentsImage: file(
      relativePath: { eq: "construction/attachments/cce-snow-attachments.jpg" }
    ) {
      ...SharpImage480
    }
    steelTracksImage: file(relativePath: { eq: "construction/attachments/cce-steel-tracks.jpg" }) {
      ...SharpImage480
    }
  }
`

export default ConstructionAttachmentsPage
